<template>
    <main class="home">
        <div class="label">
            <div class="content">
                <div class="row">
                    <div class="label-title"><img src="/gura.png" alt="">
                        <div class="line"></div>
                    </div>
                    <a target="_blank" href="https://twitter.com/GURATOKEN"><img style="filter:invert(1);height:auto;width:64px;margin: 1rem 1rem 0rem 0rem;" src="~/@/assets/twitter.svg" alt=""></a>
                </div>
                <div class="row">
                    <p class="cod" style="letter-spacing: 0.4px;margin-left: 0.5rem;margin-top:-4px;font-size:2rem;line-height:1rem;">ERC20 MINTED BY GURACORP</p>
                </div>
                <div class="row buttonrow">
                    <a target="_blank" href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x5b4C95f76b8BD3aDA1D8Baa717cf658e14cF0DBF&chainId=1">
                        <div class="buttonbuy">
    
                            <p class="bay grad">BUY</p>
                        </div>
                    </a>
                    <div class="buttoncol" style="width: 6.75rem;">
                        <a target="_blank" href="https://www.dextools.io/app/en/ether/pair-explorer/0xc0affcde0a28033d21fdf1d51104885c3e10db73">
                            <div class="buttondex">
                                <p class="chk grad">DEXTOOLS</p>
                            </div>
                        </a>
                        <a target="_blank" href="https://etherscan.io/token/0x5b4c95f76b8bd3ada1d8baa717cf658e14cf0dbf">
                            <div class="buttondex2">
                                <p class="chk grad">ETHERSCAN</p>
                            </div>
                        </a>
                    </div>
                    <div class="lines">
                        <p class="bay blk grad">ERC20</p>
                    </div>
                </div>
                <div class="row" style="margin-left: 0.5rem!important;">
    
                    <ssh-pre dark language="js" style="filter: hue-rotate(-32deg);box-shadow: 0px 2px 5px rgba(0,0,0,0.5);border-radius: 8px !important;overflow-y:scroll;max-height:395px;font-size:8px;text-align:left;">
                        SPDX-License-Identifier: MIT pragma solidity ^0.8.0; import "@uniswap/v2-periphery/contracts/interfaces/IERC20.sol"; import "@openzeppelin/contracts/utils/Context.sol"; contract ERC20 is Context, IERC20 { mapping(address => uint256) private _balances;
                        mapping(address => mapping(address => uint256)) private _allowances; uint256 private _totalSupply; string private _name; string private _symbol; /** * @dev Sets the values for {name} and {symbol}. * * The defaut value of {decimals}
                        is 18. To select a different value for * {decimals} you should overload it. * * All two of these values are immutable: they can only be set once during * construction. */ constructor(string memory name_, string memory symbol_) { _name
                        = name_; _symbol = symbol_; } /** * @dev Returns the name of the token. */ function name() public view virtual override returns (string memory) { return _name; } /** * @dev Returns the symbol of the token, usually a shorter version
                        of the * name. */ function symbol() public view virtual override returns (string memory) { return _symbol; } /** * @dev Returns the number of decimals used to get its user representation. * For example, if `decimals` equals `2`, a
                        balance of `505` tokens should * be displayed to a user as `5,05` (`505 / 10 ** 2`). * * Tokens usually opt for a value of 18, imitating the relationship between * Ether and Wei. This is the value {ERC20} uses, unless this function
                        is * overridden; * * NOTE: This information is only used for _display_ purposes: it in * no way affects any of the arithmetic of the contract, including * {IERC20-balanceOf} and {IERC20-transfer}. */ function decimals() public view
                        virtual override returns (uint8) { return 18; } /** * @dev See {IERC20-totalSupply}. */ function totalSupply() public view virtual override returns (uint256) { return _totalSupply; } /** * @dev See {IERC20-balanceOf}. */ function balanceOf(address
                        account) public view virtual override returns (uint256) { return _balances[account]; } /** * @dev See {IERC20-transfer}. * * Requirements: * * - `recipient` cannot be the zero address. * - the caller must have a balance of at least
                        `amount`. */ function transfer(address recipient, uint256 amount) public virtual override returns (bool) { _transfer(_msgSender(), recipient, amount); return true; } /** * @dev See {IERC20-allowance}. */ function allowance(address
                        owner, address spender) public view virtual override returns (uint256) { return _allowances[owner][spender]; } /** * @dev See {IERC20-approve}. * * Requirements: * * - `spender` cannot be the zero address. */ function approve(address
                        spender, uint256 amount) public virtual override returns (bool) { _approve(_msgSender(), spender, amount); return true; } /** * @dev See {IERC20-transferFrom}. * * Emits an {Approval} event indicating the updated allowance. This is
                        not * required by the EIP. See the note at the beginning of {ERC20}. * * Requirements: * * - `sender` and `recipient` cannot be the zero address. * - `sender` must have a balance of at least `amount`. * - the caller must have allowance
                        for ``sender``'s tokens of at least * `amount`. */ function transferFrom( address sender, address recipient, uint256 amount ) public virtual override returns (bool) { _transfer(sender, recipient, amount); uint256 currentAllowance =
                        _allowances[sender][_msgSender()]; require(currentAllowance >= amount, "ERC20: transfer amount exceeds allowance"); _approve(sender, _msgSender(), currentAllowance - amount); return true; } /** * @dev Atomically increases the allowance
                        granted to `spender` by the caller. * * This is an alternative to {approve} that can be used as a mitigation for * problems described in {IERC20-approve}. * * Emits an {Approval} event indicating the updated allowance. * * Requirements:
                        * * - `spender` cannot be the zero address. */ function increaseAllowance(address spender, uint256 addedValue) public virtual returns (bool) { _approve(_msgSender(), spender, _allowances[_msgSender()][spender] + addedValue); return
                        true; } /** * @dev Atomically decreases the allowance granted to `spender` by the caller. * * This is an alternative to {approve} that can be used as a mitigation for * problems described in {IERC20-approve}. * * Emits an {Approval}
                        event indicating the updated allowance. * * Requirements: * * - `spender` cannot be the zero address. * - `spender` must have allowance for the caller of at least * `subtractedValue`. */ function decreaseAllowance(address spender,
                        uint256 subtractedValue) public virtual returns (bool) { uint256 currentAllowance = _allowances[_msgSender()][spender]; require(currentAllowance >= subtractedValue, "ERC20: decreased allowance below zero"); _approve(_msgSender(), spender,
                        currentAllowance - subtractedValue); return true; } /** * @dev Moves tokens `amount` from `sender` to `recipient`. * * This is internal function is equivalent to {transfer}, and can be used to * e.g. implement automatic token fees,
                        slashing mechanisms, etc. * * Emits a {Transfer} event. * * Requirements: * * - `sender` cannot be the zero address. * - `recipient` cannot be the zero address. * - `sender` must have a balance of at least `amount`. */ function _transfer(
                        address sender, address recipient, uint256 amount ) internal virtual { require(sender != address(0), "ERC20: transfer from the zero address"); require(recipient != address(0), "ERC20: transfer to the zero address"); _beforeTokenTransfer(sender,
                        recipient, amount); uint256 senderBalance = _balances[sender]; require(senderBalance >= amount, "ERC20: transfer amount exceeds balance"); _balances[sender] = senderBalance - amount; _balances[recipient] += amount; emit Transfer(sender,
                        recipient, amount); } /** @dev Creates `amount` tokens and assigns them to `account`, increasing * the total supply. * * Emits a {Transfer} event with `from` set to the zero address. * * Requirements: * * - `to` cannot be the zero
                        address. */ function _mint(address account, uint256 amount) internal virtual { require(account != address(0), "ERC20: mint to the zero address"); _beforeTokenTransfer(address(0), account, amount); _totalSupply += amount; _balances[account]
                        += amount; emit Transfer(address(0), account, amount); } /** * @dev Destroys `amount` tokens from `account`, reducing the * total supply. * * Emits a {Transfer} event with `to` set to the zero address. * * Requirements: * * - `account`
                        cannot be the zero address. * - `account` must have at least `amount` tokens. */ function _burn(address account, uint256 amount) internal virtual { require(account != address(0), "ERC20: burn from the zero address"); _beforeTokenTransfer(account,
                        address(0), amount); uint256 accountBalance = _balances[account]; require(accountBalance >= amount, "ERC20: burn amount exceeds balance"); _balances[account] = accountBalance - amount; _totalSupply -= amount; emit Transfer(account,
                        address(0), amount); } /** * @dev Sets `amount` as the allowance of `spender` over the `owner` s tokens. * * This internal function is equivalent to `approve`, and can be used to * e.g. set automatic allowances for certain subsystems,
                        etc. * * Emits an {Approval} event. * * Requirements: * * - `owner` cannot be the zero address. * - `spender` cannot be the zero address. */ function _approve( address owner, address spender, uint256 amount ) internal virtual { require(owner
                        != address(0), "ERC20: approve from the zero address"); require(spender != address(0), "ERC20: approve to the zero address"); _allowances[owner][spender] = amount; emit Approval(owner, spender, amount); } /** * @dev Hook that is called
                        before any transfer of tokens. This includes * minting and burning. * * Calling conditions: * * - when `from` and `to` are both non-zero, `amount` of ``from``'s tokens * will be to transferred to `to`. * - when `from` is zero, `amount`
                        tokens will be minted for `to`. * - when `to` is zero, `amount` of ``from``'s tokens will be burned. * - `from` and `to` are never both zero. * * To learn more about hooks, head to xref:ROOT:extending-contracts.adoc#using-hooks[Using
                        Hooks]. */ function _beforeTokenTransfer( address from, address to, uint256 amount ) internal virtual {} } </ssh-pre>
                    <div>
                        <div class="qr"> <img style="" src="/qr.png" alt=""></div>
                        <div style="margin-left:1rem;">
                            <p class="cod" style="text-align:left;font-size:9.6px;">0x5b4C95f76b8BD3aDA1D8Baa717cf658e14cF0DBF</p>
                            <p class="chk" style="text-align:left;font-size:5.8px;">0x5b4C95f76b8BD3aDA1D8Baa717cf658e14cF0DBF</p>
                            <p class="bay" style="font-size:15px;text-align:left;">v0.8.9+commit.e5eed63a </p>
                            <svg style="filter:saturate(2)brightness(1.5);" width="115" height="182" viewBox="0 0 115 182" fill="none" class="css-1exo2l1 e1p6yfdy0">
            <path style="animation:glow 9s ease infinite;" d="M57.5054 181V135.84L1.64064 103.171L57.5054 181Z" fill="#db5e01aa" stroke="#1616B4" stroke-linejoin="round"></path>
            <path style="animation:glow 10s ease infinite;" d="M57.6906 181V135.84L113.555 103.171L57.6906 181Z" fill="#db5e01aa" stroke="#1616B4" stroke-linejoin="round"></path>
            <path style="animation:glow 20s ease infinite;" d="M57.5055 124.615V66.9786L1 92.2811L57.5055 124.615Z" fill="#db5e01aa" stroke="#1616B4" stroke-linejoin="round"></path>
            <path style="animation:glow 8s ease infinite;" d="M57.6903 124.615V66.9786L114.196 92.2811L57.6903 124.615Z" fill="#db5e01aa" stroke="#1616B4" stroke-linejoin="round"></path>
            <path style="animation:glow 10s ease infinite;" d="M1.00006 92.2811L57.5054 1V66.9786L1.00006 92.2811Z" fill="#db5e01ee" stroke="#1616B4" stroke-linejoin="round"></path>
            <path style="animation:glow 6s ease infinite;" d="M114.196 92.2811L57.6906 1V66.9786L114.196 92.2811Z" fill="#db5e01aa" stroke="#1616B4" stroke-linejoin="round"></path></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'

export default {
    components: { SshPre },

}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bayon&family=Chakra+Petch:wght@300;700&family=Libre+Barcode+128&display=swap');
main {
    position: fixed;
    display: flex;
    top: calc(50vh - 308px);
    left: calc(50vw - 208px);
}

* {
    transition: all 0.2s ease;
}

@keyframes glow {
    0% {
        opacity: 1
    }
    20% {
        opacity: 0.3
    }
    50% {
        opacity: 0.6
    }
    70% {
        opacity: 0.3
    }
    100% {
        opacity: 1
    }
}

@keyframes bright {
    0% {
        filter: blur(100px)brightness(1);
    }
    50% {
        filter: blur(100px)brightness(2);
    }
    100% {
        filter: blur(100px)brightness(1);
    }
}

.bay {
    font-family: 'Bayon', sans-serif;
}

.chk {
    font-family: 'Chakra Petch', sans-serif;
}

.cod {
    font-family: 'Libre Barcode 128', cursive;
}

.ssh-pre--dark .comment {
    color: #db5e01 !important;
    text-shadow: 0px 0px 5px;
}

a {
    color: #a5a5a5;
    text-decoration: none;
}

.blk {
    color: black;
}

@keyframes flow {
    0% {
        background-position-y: 0px;
    }
    100% {
        background-position-y: 30px;
    }
}

path {
    &:hover {
        filter: hue-rotate(360deg);
        opacity: 1 !important;
        fill-rule: #db5e01;
    }
}

.grad {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left, #c50000a5, #FFA000);
}

.triangle {}

.lines {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5), inset 0px 0px 10px black;
    border-radius: 0.5rem;
    background-image: url(/lines.svg);
    background-size: 30px;
    width: 9.4rem;
    height: 4.33rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: -0.22rem;
    animation: flow 1s linear infinite;
    background-color: #483f2a;
    border: 4px solid black;
    p {
        font-size: 3rem;
        text-align: left;
        padding: 0rem 1rem;
        line-height: 4rem;
        text-shadow: 0px 5px 10px;
        color: #db5e01;
    }
}

.buttonbuy {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    margin: 0.5rem;
    border-radius: 0.5rem;
    background: black;
    padding: 0rem 1rem;
    color: #db5e01;
    font-size: 3rem;
    line-height: 4.33rem;
    &:hover {
        text-shadow: 0px 0px 10px;
    }
}

.qr {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    height: 150px;
    background-image: linear-gradient(to top, #C50100, #FFA000);
    width: 150px;
    overflow: hidden;
    border-radius: 0.5rem;
    margin: 0.5rem;
    img {
        padding: 0.5rem;
        mix-blend-mode: exclusion;
        height: 150px;
        width: 150px;
        filter: invert(1)brightness(0.7);
    }
    &:hover {
        img {
            filter: invert(1)brightness(1);
        }
    }
}

.buttondex {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    &:hover {
        color: white;
        text-shadow: 0px 0px 10px;
    }
    background: black;
    margin: 0.5rem 0rem;
    height: 30px;
    padding: 0.31rem 0.41rem;
    img {
        filter: invert(1);
        width: 64px;
        height: auto;
    }
}

.buttondex2 {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    &:hover {
        color: white;
        text-shadow: 0px 0px 10px;
    }
    background: black;
    margin: 0.5rem 0rem;
    height: 30px;
    padding: 0.31rem 0.41rem;
    img {
        width: 20px;
        height: auto;
    }
}

.buttonrow {
    display: flex;
    flex-flow: row;
    padding: 0rem 0rem;
}

.label {
    height: 600px;
    width: 400px;
    margin: auto;
    display: flex;
    flex-flow: wrap;
    position: relative;
    .content {
        mix-blend-mode: overlay;
        height: 600px;
        width: 400px;
        z-index: 2;
        padding: 8px 0px 0px 16px;
        overflow: hidden;
        .row {
            height: max-content;
            display: flex;
            flex-flow: row;
        }
    }
    .label-title {
        width: 100%;
        position: relative;
        display: flex;
        margin-bottom: 20px;
        img {
            width: 300px;
            transition: all 0.2s ease;
            padding-top: 20px;
            padding-left: 10px;
            padding-right: 30px;
            padding-bottom: 5px;
            margin-top: -5px;
        }
        &:hover {
            img {
                filter: invert(1)brightness(0.4)sepia(1)saturate(10);
            }
        }
    }
    &::before {
        background-image: url(/card.svg);
        height: 616px;
        position: absolute;
        top: 0px;
        width: 416px;
        margin: auto;
        content: '';
        z-index: 1;
        display: flex;
        flex-flow: wrap;
    }
    &::after {
        content: '';
        background-image: url(/card.svg);
        height: 616px;
        position: absolute;
        top: 5px;
        width: 416px;
        margin: auto;
        z-index: 0;
        display: flex;
        flex-flow: wrap;
        filter: blur(100px)brightness(1);
    }
}

@media screen and (max-width:416px) {
    .label {
        transform: scale(0.8);
    }
}
</style>
