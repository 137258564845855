<template>
    <div id="nav" v-if="$store.state.user">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        <button @click="$store.dispatch('logout')">Logout</button>
    </div>
    <router-view/>
</template>

<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            store.dispatch('fetchUser')
        })
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
}

#nav a.router-link-exact-active {}
</style>
